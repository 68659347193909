import React, { useContext, useEffect, useState } from 'react'
import styles from './FollowUpBody.module.scss'
import classnames from 'classnames'
import { BodySymptom, FollowUpBodyPropsModel } from './FollowUpBody.model'
import Grid from '@mui/material/Grid'
import { AudioContext } from 'context/AudioContext'

export const FollowUpBody: React.FC<FollowUpBodyPropsModel> = (props) => {
  const { title, subtitle, image, symptoms } = props
  const { playAudio } = useContext(AudioContext)
  const [selected, setSelected] = useState<BodySymptom>()
  const [wigglingKey, setWigglingKey] = useState<string | null>(null)

  // Duration of wigle animation
  const wiggleInterval = 4000

  useEffect(() => {
    if (!selected) {
      const interval = setInterval(() => {
        const keys = Object.keys(symptoms)
        const randomKey = keys[Math.floor(Math.random() * keys.length)]
        setWigglingKey(randomKey)
      }, wiggleInterval)

      return () => clearInterval(interval)
    } else {
      setWigglingKey(null)
    }
  }, [selected, symptoms])

  const handleClick = (key: keyof typeof symptoms) => {
    const symptom = symptoms[key]
    setSelected(symptom)

    if (symptom?.voices && symptom?.id) {
      playAudio?.(`${symptom.id}-symptom`)
    }
  }

  const renderImages = () => (
    <>
      {Object.keys(symptoms).map((key) => (
        <button
          key={`follow-up-body-option-${key}`}
          className={classnames(
            styles['images-circle'],
            styles[`images-${key}`],
            {
              [styles['images-selected']]:
                selected?.iconImage ===
                symptoms[key as keyof typeof symptoms].iconImage,
              [styles['images-wiggle']]: wigglingKey === key && !selected
            }
          )}
          aria-label={key}
          onClick={() => handleClick(key as keyof typeof symptoms)}
        >
          <img
            src={symptoms[key as keyof typeof symptoms].iconImage}
            alt={key}
          />
        </button>
      ))}
    </>
  )

  return (
    <div className={styles.followupbody}>
      <Grid container spacing={2}>
        <Grid item xs={4} className={styles.leftColumn}>
          {title && (
            <h1
              className={classnames('screen-titles--title', styles.title)}
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {subtitle && (
            <p
              className={classnames('screen-titles--subtitle', styles.subtitle)}
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          )}
          <div className={styles.list}>
            <ul>
              {selected?.symptomItems.map((item, index) => (
                <li key={`follow-up-body-symptom-${index}`}>
                  <h3>{item}</h3>
                </li>
              ))}
            </ul>
          </div>
        </Grid>

        <Grid item xs={8} className={styles.rightColumn}>
          <div className={classnames('images', styles.images)}>
            <img src={image} alt="Body Diagram" />
            {renderImages()}
          </div>
        </Grid>
      </Grid>
    </div>
  )
}
