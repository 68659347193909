import React from 'react'
import styles from './AddMultiDate.module.scss'
import classnames from 'classnames'
import { colors } from 'shared/theme/theme'
import { AddMultiDatePropsModel } from './AddMultiDate.model'
import { Box, Paper } from '@mui/material'
import { Icon } from 'components/Icons/Icon.component'
import { IconType } from 'components/Icons/Icon.model'
import { Carousel } from 'components/Carousel/Carousel.component'
import { ordinal_suffix_of } from 'shared/utils/ordinalSuffix/ordinalSuffix'
import { BorderType } from 'components/Border/Border.model'
import { Border } from 'components/Border/Border.component'
import { useColourPicker } from 'context/ColourContext'
import * as iterUtils from 'shared/utils/iterUtils/iterUtils'

export const AddMultiDate: React.FC<AddMultiDatePropsModel> = (props) => {
  const {
    title,
    subtitle,
    dates,
    addDate,
    requiredDate,
    disabled = false
  } = props

  const { secondaryColour } = useColourPicker()
  const otherRequire = dates ? requiredDate - dates.length : requiredDate

  return (
    <Box className={classnames(styles.addmultidate)}>
      <h1
        className={classnames(
          'screen-titles--title',
          styles['addmultidate__title']
        )}
      >
        {title}
      </h1>
      {subtitle && (
        <h2
          className={classnames(
            'screen-titles--subtitle',
            styles['addmultidate__subtitle']
          )}
        >
          {subtitle}
        </h2>
      )}
      <Carousel>
        {dates?.map((d, index) => {
          return (
            <div
              key={`add-multidate-saved-date-${index}`}
              className={classnames(styles['addmultidate__slide-date'])}
            >
              <h3 className={classnames(styles['addmultidate__slide-title'])}>
                {index == 0
                  ? 'Last day'
                  : `${ordinal_suffix_of(index + 1)} last day`}
              </h3>
              <div className={styles['addmultidate__slide-wrapper']}>
                <Border
                  type={BorderType.Card1}
                  fill={colors.white}
                  dropShadow
                  withTitle
                  titleColor={secondaryColour}
                />
                <Paper
                  className={classnames(
                    'u-border-style-sketch',
                    styles['addmultidate__slide'],
                    styles['addmultidate__slide--date']
                  )}
                >
                  <div className={classnames(styles['addmultidate__month'])}>
                    {d.toLocaleString('default', { month: 'long' })}
                  </div>
                  <div className={classnames(styles['addmultidate__day'])}>
                    {d.toLocaleString('default', { day: 'numeric' })}
                  </div>
                </Paper>
              </div>
            </div>
          )
        })}
        {iterUtils.range(otherRequire).map((value) => {
          return (
            <button
              key={`add-multidate-add-date-${value}`}
              // eslint-disable-next-line @typescript-eslint/no-empty-function
              onClick={disabled ? () => {} : addDate}
              className={classnames(
                'u-border-style-sketch',
                styles['addmultidate__slide'],
                styles['addmultidate__adddate'],
                disabled && styles['addmultidate__disabled']
              )}
            >
              <Border type={BorderType.Card1} />
              <Icon
                className={classnames(styles['addmultidate__icon'])}
                icon={IconType.Plus}
                size={36}
                stroke={colors.white}
                fill={colors.black}
                border={true}
              />
              <h4>Add Date</h4>
            </button>
          )
        })}
      </Carousel>
    </Box>
  )
}
