import { createSelector, isPlain } from '@reduxjs/toolkit'
import { REDUX_STORE_OFFLINE_FIELDS } from 'shared/constants/redux'
import type { SurveyState } from './type'

/**
 * Array of selector functions, which, altogether, return all fields required to be
 * defined to use the app offline in RA mode.
 */
const RA_MODE_OFFLINE_SELECTORS = REDUX_STORE_OFFLINE_FIELDS.map(
  (field) => (state: SurveyState) => state[field]
)

/**
 * A Redux selector function whether the Redux store in use by the app has all properties
 * required for offline use defined (when logging in as an RA). This selector will use
 * memoisation under the hood by checking whether any of the required-to-be-defined Redux
 * state fields have changed in value (by doing a reference equality check).
 *
 * @param state - The current Redux survey state.
 *
 * @returns `true` if all Redux properties required for offline app use are defined,
 * `false` otherwise.
 */
export const storeSupportsRaModeOfflineSelector = createSelector(
  RA_MODE_OFFLINE_SELECTORS,
  (...args) => args.every((arg) => arg != null)
)

/**
 * A function which determines whether the underlying type of a provided value may be
 * serialised and stored in IndexedDB.
 *
 * Per MDN and W3C (https://w3c.github.io/IndexedDB/#value-construct), IndexedDB supports
 * storing file-like objects (which would be covered by the {@link Blob}) interface,
 * {@link ImageData} files, any {@link ArrayBuffer} view, a JavaScript {@link Date}
 * object, and a {@link RegExp} type, in addition to plain objects, arrays, and other
 * primitive types.
 *
 * @param value - The value who's type will be checked to see if it may be stored as a
 * valid value in IndexedDB.
 *
 * @returns `true` if the value is able to be stored in IndexedDB, `false` otherwise.
 */
export const isIndexedDbSerializable = (value: any): boolean => {
  return (
    value instanceof ImageData ||
    value instanceof Blob ||
    ArrayBuffer.isView(value) ||
    value instanceof Date ||
    value instanceof RegExp ||
    isPlain(value)
  )
}
