import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react'
import type { ReactNode } from 'react'
import type { Dispatch } from 'redux'
import { Button } from 'components/Button/Button.component'
import { Modal } from 'components/Modals/Modal/Modal.component'
import { ModalComponent, ComponentType } from './ModalScreen.model'
import type {
  ModalScreenPropsModel,
  FollowUpOptionValue,
  FollowUpOptionValues
} from './ModalScreen.model'
import { useDispatch } from 'react-redux'
import { shortcodesRender } from 'shared/utils/shortcodesRender/shortcodesRender'
import { updateSurveyAnswer } from 'store/reducer'
import type { ISurveyData } from 'store/type'
import type { FollowUpQuestionModel } from 'api/client.model'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { AudioContext } from 'context/AudioContext'
import styles from './ModalScreen.module.scss'
import { IconType } from 'components/Icons/Icon.model'
import { useColourPicker } from 'context/ColourContext'

export const ModalScreen: React.FC<ModalScreenPropsModel> = (props) => {
  const dispatch: Dispatch<any> = useDispatch()
  const {
    screenId,
    modalData,
    open,
    onModalScreenClose,
    shortcodes,
    optionFollowUp,
    value,
    setValue,
    variation
  } = props

  const { primaryColour, secondaryColour } = useColourPicker()

  const { playAudio } = useContext(AudioContext)
  const { showAdditionalFollowUpQuestion } = matchCondition()

  const { additionalQuestions = [] } = modalData

  const questions = useMemo<
    FollowUpQuestionModel[]
  >((): FollowUpQuestionModel[] => {
    if (value) {
      const checkQuestions =
        additionalQuestions?.filter((q) =>
          showAdditionalFollowUpQuestion(value, q?.conditions)
        ) || []

      if (showAdditionalFollowUpQuestion(value, modalData.conditions)) {
        checkQuestions.unshift({
          ...modalData,
          id: 'main'
        })
      }

      return checkQuestions
    } else {
      return []
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalData, value])

  // optionValues for main follow up and additional questions
  const [optionValues, setOptionValues] = useState<FollowUpOptionValues>()

  const [curQuestionId, setCurQuestionId] = useState<string>()

  // Track progress of additional questions
  const [progress, setProgress] = useState<string[]>([])

  const curQuestion =
    curQuestionId === 'main'
      ? modalData
      : additionalQuestions?.find((q) => q?.id === curQuestionId)

  const field = curQuestion?.surveyField

  /**
   * Handle the current question ID being set, including setting the current option values
   * of this component.
   *
   * @param questionId - The question ID to set as the current question ID.
   */
  const handleSetCurQuestionId = (questionId: string) => {
    setCurQuestionId(questionId)

    // Reset value when name changes (or question ID I guess?)
    if (questionId) {
      setOptionValues((values) => ({ ...values, [questionId]: undefined }))
    }
  }

  useEffect(() => {
    const current = questions?.[0]?.id || 'main'
    handleSetCurQuestionId(current)
    setProgress([current])
    setOptionValues(undefined)
  }, [questions])

  // Get next question id or return null
  const nextQuestion = useMemo<string | null>((): string | null => {
    // Check for next question in additional questions and has not been displayed yet
    return questions?.find((q) => !progress.includes(q?.id || ''))?.id || null
  }, [questions, progress])

  const Component: React.FC<any> = useMemo(
    () =>
      curQuestion?.data
        ? ModalComponent[Object.keys(curQuestion?.data)[0] as ComponentType]
        : ('div' as any),
    [curQuestion]
  )

  const getSurveyFieldValue = (key: string) => {
    if (!optionValues?.[key]) return undefined
    return optionValues[key]
  }

  const handleModalClose = (cancel?: boolean) => {
    const mainField = modalData?.surveyField

    if (mainField) {
      const surveyAnswer = cancel ? undefined : getSurveyFieldValue('main')
      dispatch(updateSurveyAnswer({ [mainField]: surveyAnswer } as ISurveyData))
    }

    additionalQuestions?.forEach((question) => {
      const { id, surveyField } = question
      if (surveyField && id) {
        const surveyAnswer = cancel ? undefined : getSurveyFieldValue(id)
        dispatch(
          updateSurveyAnswer({ [surveyField]: surveyAnswer } as ISurveyData)
        )
      }
    })

    if (onModalScreenClose) {
      let valueCheck = true
      questions.forEach((question) => {
        if (!optionValues?.[question?.id || '']) {
          valueCheck = false
        }
      })

      setOptionValues(undefined)
      // When `cancel` is true, The `progress` should not be updated. Because in
      // such case, the user just cancels the actions and closes the Modal screen.
      if (!cancel) {
        setProgress([])
      }

      onModalScreenClose(!cancel && valueCheck)
    } else {
      setOptionValues(undefined)
      setProgress([])
    }
  }

  const handleNextQuestion = (next: string) => {
    if (next) {
      setProgress([...progress, next])
      handleSetCurQuestionId(next)
    }
  }

  const handleSetValue = useCallback(
    (choices: FollowUpOptionValue) => {
      if (curQuestionId) {
        setOptionValues({
          ...optionValues,
          [curQuestionId]: choices
        })

        let value: FollowUpOptionValue = choices

        if (Array.isArray(choices) && typeof choices[0] === 'string') {
          value = choices
        } else if (typeof choices === 'object') {
          value = (choices as any)[Object.keys(choices)[0]]
          setOptionValues({
            ...optionValues,
            [curQuestionId]: value
          })
        }

        if (field) {
          dispatch(
            updateSurveyAnswer({
              [field]: value
            } as ISurveyData)
          )
        }
      }
    },
    [dispatch, field, optionValues, curQuestionId]
  )

  useEffect(() => {
    if (open && (screenId || optionFollowUp) && curQuestion?.voices) {
      let audioReference = ''

      // Use followUp option audio if availble on main screen, else use followUpQustion voices or additional question voices
      if (optionFollowUp) {
        audioReference = `${optionFollowUp}-option-followup`
      } else if (curQuestionId === 'main') {
        audioReference = `${screenId}-modal`
      } else if (curQuestionId) {
        audioReference = `${curQuestionId}-modal`
      }

      if (audioReference) playAudio?.(audioReference)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, curQuestionId])

  const renderModalButton = (): ReactNode | undefined => {
    if (variation === 'info') {
      return (
        <div className={styles['modalscreen-closeButton']}>
          <Button
            className={styles.button}
            noBorder={true}
            icon={IconType.Close}
            onClick={() => handleModalClose(true)}
          ></Button>
        </div>
      )
    }

    return (
      <>
        <Button
          variation="primary"
          width="l"
          onClick={() => handleModalClose(true)}
        >
          Close
        </Button>
        <Button
          variation="primary"
          width="l"
          disabled={
            !optionValues || (!!curQuestionId && !optionValues?.[curQuestionId])
          }
          onClick={() => {
            // This is a hotfix of 'Other' button affecting the flows
            // https://github.com/sydney-uni-ict/Drug_App/issues/36
            if (
              setValue !== undefined &&
              Array.isArray(value) &&
              optionValues !== undefined
            ) {
              const valueList: string[] = []

              value.forEach((v) => {
                if (typeof v === 'string') valueList.push(v)
              })
              if (optionValues && Array.isArray(optionValues.main)) {
                const optionValuesList = optionValues.main as string[]
                optionValuesList.forEach((ov) => valueList.push(ov))
              }
              setValue(valueList)
            }

            nextQuestion
              ? handleNextQuestion(nextQuestion)
              : handleModalClose(false)
          }}
        >
          Confirm
        </Button>
      </>
    )
  }

  return (
    <div className={styles.modalscreen}>
      <Modal
        // Pass the variation label to the modal
        variation={variation}
        className={styles['modalscreen__modal']}
        open={open}
        customClose={handleModalClose}
        style={{
          backgroundColor: primaryColour
        }}
        invalid={!!nextQuestion}
        fullWidth
        buttons={renderModalButton()}
      >
        {curQuestionId && curQuestion?.data && (
          <Component
            {...curQuestion.data[Object.keys(curQuestion.data)[0]]}
            title={shortcodesRender(
              shortcodes,
              curQuestion.data[Object.keys(curQuestion.data)[0]].title
            )}
            subtitle={shortcodesRender(
              shortcodes,
              curQuestion.data[Object.keys(curQuestion.data)[0]].subtitle
            )}
            key={`modal-screen-clear-${
              Object.keys(curQuestion.data)[0]
            }-${open}`} // clear value on modal open/close
            setValue={handleSetValue}
            color={secondaryColour}
            defaultValue={optionValues?.[curQuestionId]}
            playAudio={playAudio}
          />
        )}
      </Modal>
    </div>
  )
}
