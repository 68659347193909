import { Container } from 'components/GrogConsumptions/Container/Container.component'
import { Screen } from 'components/Screen/Screen.component'
import React, { useState } from 'react'
import { GLOBAL_SHORTCODES } from 'shared/constants/shortcodes/Shortcodes.d'
import { colors } from 'shared/theme/theme'
import { AlcoholCalc } from 'shared/utils/alcoholCalculation/alcoholCalculation'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import {
  outroGlobalShortcodesRender,
  shortcodesRender
} from 'shared/utils/shortcodesRender/shortcodesRender'
import type { StandardDrinksScreenPropsModel } from './StandardDrinksScreen.model'
import styles from './StandardDrinksScreen.module.scss'

export const StandardDrinksScreen: React.FC<StandardDrinksScreenPropsModel> = (
  props
) => {
  const {
    currentScreenId,
    headerProps,
    screenData: {
      title,
      subtitle,
      image,
      maskImage,
      topBorder,
      bottomBorder,
      color
    },
    footerProps,
    shortcodes
  } = props

  const { getNextScreenId } = matchCondition()
  const { calcTotalStandardDrinks } = AlcoholCalc()

  const [amount] = useState<number>(calcTotalStandardDrinks)

  const handleFooterProps = () => {
    const updatedFooterProps = { ...footerProps }

    // Next Screen Condition Logic
    updatedFooterProps.nextScreenId = getNextScreenId(
      undefined,
      footerProps.conditions,
      footerProps.nextScreenId
    )

    return updatedFooterProps
  }

  const calcImageHeight = () => {
    if (amount == null || amount === 0) {
      return 250
    }

    if (Math.ceil(amount) > 12) {
      return 100
    } else if (Math.ceil(amount) > 6) {
      return 200
    } else {
      return 250
    }
  }

  const calcColumnNumber = () => {
    if (amount == null || amount === 0) {
      return 6
    }

    if (Math.ceil(amount) > 24) {
      return 10
    } else {
      return 6
    }
  }

  return (
    <div className={`drug-app-screen ${styles.standarddrinksscreen}`}>
      <Screen
        currentScreenId={currentScreenId}
        headerProps={headerProps}
        footerProps={handleFooterProps()}
      >
        <div className={styles['standarddrinksscreen']}>
          <div className={styles['standarddrinksscreen-titles']}>
            {title && (
              <h1
                className="screen-titles--title"
                dangerouslySetInnerHTML={{
                  __html:
                    shortcodesRender(
                      shortcodes,
                      outroGlobalShortcodesRender(
                        GLOBAL_SHORTCODES.OUTRO_STANDARD_DRINKS,
                        title,
                        undefined,
                        amount ? Math.round(amount * 100) / 100 : undefined
                      )
                    ) ||
                    outroGlobalShortcodesRender(
                      GLOBAL_SHORTCODES.OUTRO_STANDARD_DRINKS,
                      title,
                      undefined,
                      amount ? Math.round(amount * 100) / 100 : undefined
                    )
                }}
              />
            )}
            {subtitle && (
              <p
                className="screen-titles--subtitle"
                dangerouslySetInnerHTML={{ __html: subtitle }}
              />
            )}
          </div>
          <div
            className={styles['standarddrinksscreen-amount']}
            style={{
              gridTemplateColumns: `repeat(${calcColumnNumber()}, 1fr)`,
              maxHeight: window.outerHeight < 850 ? 400 : 500,
              overflowY: 'scroll'
            }}
          >
            {amount != 0 &&
              amount &&
              Array.from(Array(Math.ceil(amount)).keys()).map((index) => (
                <Container
                  key={`standard-drinks-item-${index}`}
                  image={image}
                  fillMaskImage={maskImage}
                  topBorder={topBorder}
                  bottomBorder={bottomBorder}
                  drinkValue={
                    index + 1 < amount
                      ? 100
                      : (amount - Math.floor(amount)) * 100
                  }
                  drinkColor={color || colors.standardDrink}
                  maxHeight={calcImageHeight()}
                />
              ))}
          </div>
        </div>
      </Screen>
    </div>
  )
}
