import React, { memo, ReactElement, useLayoutEffect } from 'react'
import type { GrogImageProps } from './GrogImage.model'
import { Product } from 'components/GrogConsumptions/Product/Product.component'
import {
  calcNumStepsPerProductImage,
  productIsPack
} from 'shared/utils/alcoholCalculation/alcoholCalculation'
import styles from './GrogImage.module.scss'
import classnames from 'classnames'
import { setCSSVariable } from 'shared/utils/styleUtils/styleUtils'

// Constant dictating maximum number of icons per row
const MAX_ICONS_PER_ROW = 15

/**
 * Generate vertical or horizontal dynamic slider image
 * @returns {ReactElement} dynamic image
 */
export const GrogImage = memo(function GrogImage({
  customComponent,
  image,
  min,
  maxImages,
  maxDrinks,
  rangeValue,
  subContainer,
  isShare,
  stepsPerSubContainer
}: GrogImageProps): ReactElement {
  const isPack = productIsPack(subContainer)

  // Set the maximum number of icons per row via a CSS variable
  useLayoutEffect(() => {
    setCSSVariable('--max-icons-per-row', MAX_ICONS_PER_ROW.toString())
  }, [])

  // Calculate the initial value for the slider
  const tempValue = Number(rangeValue) || min

  // Calculate the number of steps per grog image
  const stepsPerImage = calcNumStepsPerProductImage({
    subContainer,
    stepsPerSubContainer
  })

  /**
   * Calculate the index for the current slider value,
   * which determines the number of fully opaque icons.
   */
  const tempIndex = Math.ceil(tempValue / stepsPerImage)

  // Calculate the number of full-opacity rows
  const fullOpacityRows = Math.floor(
    (isShare ? maxImages : tempIndex) / MAX_ICONS_PER_ROW
  )

  // Adjust the icon height based on the number of full-opacity rows
  const iconHeight = 50 / (0.3 * fullOpacityRows)
  setCSSVariable('--standard-container-height', `${iconHeight}px`)

  if (customComponent) {
    const opacityFn = (index: number) =>
      tempValue <= index ? (isShare ? 0.2 : 0) : 1

    return (
      <div
        className={classnames(
          styles['grog-image__dynamic--horizontal-wrapper'],
          {
            [styles['grog-image__dynamic--horizontal-wrapper-share']]: isShare
          }
        )}
      >
        {new Array(maxImages).fill(0).map((_, index) => (
          <div
            className={classnames(
              styles['grog-image__dynamic--horizontal-item']
            )}
            style={{
              opacity: opacityFn(index)
            }}
            key={`grog-custom-item-${index}`}
          >
            {customComponent}
          </div>
        ))}
      </div>
    )
  }

  // Calculation to crop last image, if the slider is asking what the user's share of the
  // group's drinks are, and the group only had a fraction of the last "container"
  const maxSliderSteps = maxImages * stepsPerImage
  const lastImageStepsDifference = maxSliderSteps - maxDrinks

  let lastImageSteps
  if (lastImageStepsDifference === 0) {
    lastImageSteps = stepsPerImage
  } else {
    lastImageSteps = stepsPerImage - lastImageStepsDifference
  }

  // Calculate the percentage to display the last image
  const lastImageDisplayPercentage = (lastImageSteps / stepsPerImage) * 100

  return (
    <div
      className={classnames(styles['grog-image__dynamic--horizontal-wrapper'], {
        [styles['grog-image__dynamic--horizontal-wrapper-share']]: isShare
      })}
    >
      {new Array(maxImages).fill(0).map((_, index) => {
        // Existing calculations from previous codebase
        const diff = tempValue - index * stepsPerImage

        let clipPercentage
        if (diff > stepsPerImage) {
          clipPercentage = 0
        } else {
          clipPercentage = 100 - (diff / stepsPerImage) * 100
        }

        const opacity =
          Math.ceil(tempValue / stepsPerImage) > index || isShare ? 1 : 0

        return (
          <div
            key={`grog-item-${index}`}
            style={{
              opacity
            }}
          >
            <Product
              isPack={isPack}
              clipPercentage={clipPercentage}
              displayPercentage={
                index === maxImages - 1 ? lastImageDisplayPercentage : 100
              }
              image={image || ''}
            />
          </div>
        )
      })}
    </div>
  )
})
