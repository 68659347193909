import { Button } from 'components/Button/Button.component'
import { IconType } from 'components/Icons/Icon.model'
import { Modal } from 'components/Modals/Modal/Modal.component'
import React, { useEffect, useState, useContext, useTransition } from 'react'
import { connect, useDispatch } from 'react-redux'
import { To, useNavigate, useSearchParams } from 'react-router-dom'
import { Dispatch } from 'redux'
import {
  CONTINUE_LATER,
  GROG_DIARY_CURRENT_STATE_DEFAULT,
  LEAVE_SURVEY
} from 'shared/constants/Constants.d'
import {
  DELETE_LEAVE_STUDY,
  DELETE_REFUSE_TAKE_PART
} from 'shared/constants/messages/Messages.d'
import { colors } from 'shared/theme/theme'
import { calculateTimeDiff } from 'shared/utils/calculateTimeDiff/calculateTimeDiff'
import {
  addCompletedSurvey,
  updateSurveyAnswer,
  updateContinueSurveyResponse,
  updateContinueLocalIdentifier,
  updateUserJourney,
  updateAnalytics,
  updateLastQTime,
  logout,
  updateGrogDiaryUserJourney,
  updateGrogDiaryCurrentConsumption,
  updateOutroUserJourney,
  updateGrogDiaryCurrentSelections
} from 'store/reducer'
import { AnalyticsData, ISurveyData, SurveyState } from 'store/type'
import { FooterPropsModel } from './Footer.model'
import styles from './Footer.module.scss'
import { AudioContext } from 'context/AudioContext'

const FooterComponent: React.FC<FooterPropsModel & SurveyState> = (props) => {
  const navigate = useNavigate()
  const dispatch: Dispatch<any> = useDispatch()
  const {
    playAudio,
    stopAudio,
    hasAudio,
    activeAudio,
    screenAudioKey,
    setScreenAudioKey
  } = useContext(AudioContext)

  const {
    id,
    demoMode,
    firstscreen,
    screenName,
    followUpQuestionName,
    nextScreenId,
    surveyEnd,
    invalid,
    customNextClick,
    customBackClick,
    onForwardTransition,
    onBackwardTransition,
    survey,
    surveyData,
    surveyMetaData,
    userJourney,
    grogDiaryUserJourney,
    grogDiaryCurrentState,
    outroUserJourney,
    analytics,
    drinkingSessions,
    lastQTime,
    continueLocalIdentifier,
    toggleNextButton = false,
    backButtonHidden = false
  } = props

  const startTime = surveyMetaData?.startTime || new Date()
  const resumeTime = surveyMetaData?.resumeTime
  const duration =
    surveyMetaData?.duration != null ? surveyMetaData?.duration : 0

  const [modalOpen, setModalOpen] = useState<boolean>(false)
  const [modalChange, setModalChange] = useState<boolean>(false)
  const [demoModalChange, setDemoModalChange] = useState<boolean>(false)
  const [searchParams] = useSearchParams()
  const [isPending, startTransition] = useTransition()

  const onReplayClick = (stop: boolean) => () => {
    if (stop) {
      stopAudio?.()
    } else {
      if (screenAudioKey) {
        playAudio?.(screenAudioKey)
      } else {
        playAudio?.(id)
      }
    }
  }

  const onRouteChange = (route: string | -1) => {
    stopAudio?.()
    setScreenAudioKey?.(undefined)
    navigate(route as To)
  }

  const addAnalyticsItem = () => {
    let analyticsItem
    let timesAnswered = 1

    if (analytics) {
      const analyticsItemIndex = analytics.findIndex(
        (item) => item && item.question === screenName
      )

      analyticsItem =
        analyticsItemIndex > -1 ? analytics[analyticsItemIndex] : undefined
    }

    const timeDiff = calculateTimeDiff(
      lastQTime || startTime,
      new Date(),
      analyticsItem?.totalTime != null ? analyticsItem?.totalTime : 0,
      false,
      true
    ) as number

    timesAnswered =
      (analyticsItem?.timesAnswered != null
        ? analyticsItem?.timesAnswered
        : 0) + 1

    // Add Analytics
    if (screenName) {
      dispatch(
        updateAnalytics({
          question: screenName,
          timesAnswered,
          totalTime: timeDiff,
          avgTime: timeDiff / timesAnswered
        } as AnalyticsData)
      )
    }
  }

  /**
   * Construct the route utilised for a screen on the survey with a specific given ID.
   *
   * @param id the ID of the survey screen, as either a `string` or `number`.
   *
   * @returns The route for survey screen at the given ID.
   */
  const getSurveyRoute = (id: string | number) => {
    return `/survey/${demoMode ? 'demo/' : ''}${id}`
  }

  const onNextClick = () => {
    if (customNextClick) {
      customNextClick()
    } else {
      // I assume here, if we are at the last slide of the survey, the next button is not
      // present, and/or there is a `customNextClick` setup, and hence `nextScreenId`
      // being undefined would not matter here.
      // We can go back and fix this if needed.
      onRouteChange(getSurveyRoute(nextScreenId!))

      // Update screen path
      dispatch(updateUserJourney(Number(nextScreenId)))

      // Update Analytics
      dispatch(updateLastQTime(new Date()))
      addAnalyticsItem()

      if (onForwardTransition) {
        startTransition(() => {
          onForwardTransition()
        })
      }

      playAudio?.(nextScreenId)
    }
  }

  const onBackClick = () => {
    if (customBackClick) {
      customBackClick()
    } else {
      if (onBackwardTransition) {
        startTransition(() => {
          onBackwardTransition()
        })
      }

      handleClearSavedData()

      // Handle the `userJourney` prop being potentially undefined, OR there being no
      // previous screen (e.g. we are at the first screen).
      //
      // I believe there shouldn't be any reason for `userJourney` to be undefined, but
      // since all other properties of `SurveyState` may be undefined, this was not
      // changed for consistency reasonbs, and thus handled here.
      const lastUserJourneyID = userJourney?.[userJourney.length - 2]

      // Variable to store the "previous route" for the application.
      // If there wasn't a previous `userJourney` ID defined (as per above), this defaults
      // to "-1" to navigate to the previous page.
      let previousRoute: string | -1
      if (lastUserJourneyID) {
        previousRoute = getSurveyRoute(lastUserJourneyID)
      } else {
        previousRoute = -1
      }

      onRouteChange(previousRoute)
      if (lastUserJourneyID) {
        playAudio?.(lastUserJourneyID.toString())
      }

      // Update screen path
      dispatch(updateUserJourney(-1))

      // Update Analytics
      dispatch(updateLastQTime(new Date()))
    }
  }

  const handleClearSavedData = () => {
    if (screenName && surveyData && surveyData[screenName]) {
      const reset: ISurveyData = {
        [screenName]: undefined
      }

      if (followUpQuestionName && surveyData[followUpQuestionName]) {
        reset[followUpQuestionName] = undefined
      }

      dispatch(updateSurveyAnswer(reset))
    }
  }

  const handleModalOpen = () => () => {
    setModalChange(false)
    stopAudio?.()
    setModalOpen(true)
  }

  const handleModalClose =
    (cancel: boolean, continueLater?: boolean, showFlagDelete?: boolean) =>
    () => {
      setModalOpen(false)
      if (cancel) {
        return
      }

      const endScreen = survey?.screens.find(
        (screen) =>
          screen.surveyField === (continueLater ? CONTINUE_LATER : LEAVE_SURVEY)
      )
      if (endScreen) {
        onRouteChange(
          `${getSurveyRoute(endScreen.id)}?flagDelete=${showFlagDelete}`
        )
      }
    }

  const handleSubmit = (flagContinue: boolean, flagDelete: boolean) => {
    // Update screen path to empty on submit
    dispatch(updateUserJourney(undefined))
    dispatch(updateGrogDiaryUserJourney({ actionType: 'clear' }))

    // To be honest, I am not really sure if this delete action is needed. Is it to clear
    // the data for the next user? Or something else? Either way, I am just going to
    // follow the conventions established.
    dispatch(updateGrogDiaryCurrentConsumption({ actionType: 'delete' }))
    dispatch(updateGrogDiaryCurrentSelections({ actionType: 'clear' }))

    dispatch(updateOutroUserJourney({ actionType: 'clear' }))

    addAnalyticsItem()

    if (!continueLocalIdentifier) {
      dispatch(
        addCompletedSurvey({
          save: true,
          responses: surveyData,
          metadata: updateSubmissionMetadata(flagContinue, flagDelete),
          userJourney: flagContinue ? userJourney : undefined,
          grogDiaryUserJourney: flagContinue ? grogDiaryUserJourney : undefined,
          grogDiaryCurrentState: flagContinue
            ? grogDiaryCurrentState
            : GROG_DIARY_CURRENT_STATE_DEFAULT,
          outroUserJourney: flagContinue ? outroUserJourney : undefined,
          analytics,
          drinkingSessions
        })
      )
    } else {
      dispatch(updateContinueLocalIdentifier(continueLocalIdentifier))
      dispatch(
        updateContinueSurveyResponse({
          responses: surveyData,
          metadata: updateSubmissionMetadata(flagContinue, flagDelete),
          userJourney,
          grogDiaryUserJourney,
          grogDiaryCurrentState,
          outroUserJourney,
          analytics,
          drinkingSessions
        })
      )
    }
  }

  const updateSubmissionMetadata = (
    flagContinue: boolean,
    flagDelete: boolean
  ) => {
    let status = 'complete'
    if (flagDelete) {
      status = 'deletion'
    } else if (flagContinue) {
      status = 'incomplete'
    }

    const showFlag = Boolean(searchParams.get('flagDelete'))

    return {
      ...surveyMetaData,
      flaggedIncomplete: flagContinue || flagDelete,
      flaggedDeleted: flagDelete,
      deletionReason: flagDelete
        ? showFlag
          ? DELETE_LEAVE_STUDY
          : DELETE_REFUSE_TAKE_PART
        : undefined,
      breakPoint:
        userJourney && userJourney.length > 0
          ? userJourney?.at(-1)?.toString()
          : surveyMetaData?.breakPoint,
      flaggedComingBack: flagContinue,
      status,
      endTime: new Date(),
      duration:
        (calculateTimeDiff(resumeTime || startTime, new Date()) as number) +
        duration
    }
  }

  const handleSurveyFinish = () => {
    if (demoMode) {
      dispatch(logout())
      navigate('/ra-dashboard')
    } else {
      onRouteChange('/ra-dashboard/dashboard')
    }
  }

  // Demo Mode Skip Sections
  const getDemoModeSections = () => {
    const sectionInfo = [] as { name: string; screenId: string }[]
    let skipSection = ''
    survey?.screens.forEach((screen) => {
      if (
        screen.section.name &&
        !sectionInfo.some((el) => el.name === screen.section.name)
      ) {
        sectionInfo.push({
          name: screen.section.name,
          screenId: screen.id
        })
      }

      if (screen.section.name && screen.id === id) {
        skipSection = screen.section.name
      }
    })

    return sectionInfo.filter((info) => info.name !== skipSection)
  }

  useEffect(() => {
    if (toggleNextButton) onNextClick()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleNextButton])

  useEffect(() => {
    if ((surveyEnd || !nextScreenId) && !demoMode) {
      handleSubmit(screenName === CONTINUE_LATER, screenName === LEAVE_SURVEY)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nextScreenId, surveyEnd])

  // Push first screenId into screen path
  useEffect(() => {
    if (firstscreen) {
      dispatch(updateUserJourney(Number(id)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstscreen])

  return (
    <footer className={styles.footer}>
      <div className={styles.icons}>
        {hasAudio && (
          <>
            {activeAudio ? (
              <Button
                noBorder
                icon={IconType.Stop}
                iconPosition="top"
                onClick={onReplayClick(true)}
                style={{ minWidth: '95px', padding: '0 5px !important' }}
              >
                Stop
              </Button>
            ) : (
              <Button
                noBorder
                icon={IconType.Replay}
                iconPosition="top"
                onClick={onReplayClick(false)}
                style={{
                  minWidth: '95px',
                  padding: '0 5px !important'
                }}
              >
                Replay
              </Button>
            )}
          </>
        )}
        {nextScreenId && !surveyEnd && (
          <Button
            noBorder
            icon={IconType.Pause}
            iconPosition="top"
            onClick={handleModalOpen()}
            style={{ padding: '0 5px !important' }}
          >
            Pause
          </Button>
        )}
      </div>
      <div className={styles.buttons}>
        {!firstscreen && !surveyEnd && !backButtonHidden && (
          <Button variation="secondary" width="l" onClick={onBackClick}>
            Back
          </Button>
        )}
        {nextScreenId && !surveyEnd ? (
          <Button
            variation="primary"
            width="l"
            onClick={onNextClick}
            disabled={invalid}
          >
            Next
          </Button>
        ) : (
          <Button variation="primary" width="l" onClick={handleSurveyFinish}>
            Finish
          </Button>
        )}
      </div>
      <Modal
        className="footer-modal"
        style={{
          color: colors.white,
          backgroundColor: colors.black
        }}
        open={modalOpen}
        buttons={
          demoMode ? (
            demoModalChange ? (
              <div className="footer-modal__buttonsWrapper">
                <div className="footer-modal__skipButtons">
                  {getDemoModeSections().map((section, index) => (
                    <Button
                      key={`footer-button-${index}`}
                      onClick={() =>
                        onRouteChange(`/survey/demo/${section.screenId}`)
                      }
                      style={{
                        backgroundColor: colors.white
                      }}
                    >
                      {section.name}
                    </Button>
                  ))}
                </div>
                <Button
                  className="footer-modal__resumeButton"
                  onClick={() => setDemoModalChange(false)}
                  style={{
                    color: `${colors.white} !important`
                  }}
                >
                  Cancel
                </Button>
              </div>
            ) : (
              <div className="footer-modal__buttonsWrapper">
                <div className="footer-modal__buttons">
                  <Button
                    onClick={() => setDemoModalChange(true)}
                    style={{
                      backgroundColor: colors.white
                    }}
                  >
                    Skip to ...
                  </Button>
                  <Button
                    onClick={() => onRouteChange('/survey/demo')}
                    style={{
                      backgroundColor: colors.white
                    }}
                  >
                    Start over
                  </Button>
                  <Button
                    onClick={handleSurveyFinish}
                    style={{
                      backgroundColor: colors.white
                    }}
                  >
                    Quit demo mode
                  </Button>
                </div>
                <Button
                  className="footer-modal__resumeButton"
                  onClick={handleModalClose(true)}
                  icon={IconType.Replay}
                  iconProps={{
                    icon: IconType.Replay,
                    fill: colors.white,
                    size: 20
                  }}
                  borderColor={colors.white}
                  style={{
                    color: `${colors.white} !important`
                  }}
                >
                  Resume Survey
                </Button>
              </div>
            )
          ) : (
            <div className="footer-modal__buttons">
              <Button
                onClick={handleModalClose(true)}
                style={{
                  backgroundColor: colors.white
                }}
              >
                {modalChange
                  ? 'No, I want to continue the survey'
                  : 'Continue Survey'}
              </Button>
              <Button
                onClick={handleModalClose(false, true)}
                style={{
                  backgroundColor: colors.white
                }}
              >
                {modalChange
                  ? 'No, I want to come back to my survey later'
                  : 'I want to finish it later'}
              </Button>
              <Button
                onClick={
                  modalChange
                    ? handleModalClose(false, false, true)
                    : () => setModalChange(true)
                }
                style={{
                  backgroundColor: colors.white
                }}
              >
                {modalChange ? 'Yes' : 'I want to leave the study'}
              </Button>
            </div>
          )
        }
      >
        <h2>
          {modalChange ? (
            <span>
              Are you sure you want to stop your survey
              <br />
              (and not come back)?
            </span>
          ) : (
            'Your survey is paused'
          )}
        </h2>
      </Modal>
    </footer>
  )
}

const mapStateToProps = (state: SurveyState) => ({
  survey: state.survey,
  surveyData: state.surveyData,
  surveyMetaData: state.surveyMetaData,
  continueLocalIdentifier: state.continueLocalIdentifier,
  userJourney: state.userJourney,
  grogDiaryUserJourney: state.grogDiaryUserJourney,
  grogDiaryCurrentState: state.grogDiaryCurrentState,
  outroUserJourney: state.outroUserJourney,
  analytics: state.analytics,
  lastQTime: state.lastQTime,
  drinkingSessions: state.drinkingSessions
})

export const Footer = connect(mapStateToProps)(FooterComponent)
