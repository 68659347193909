import React from 'react'
import { connect, useDispatch } from 'react-redux'
import { UsingTypes } from 'shared/constants/Constants.d'
import type { DeepReadonly } from 'shared/types/general'
import { useHiddenScreenLogic } from 'shared/utils/hiddenScreenLogic/hiddenScreenLogic'
import { updateSurveyAnswer } from 'store/reducer'
import type { SurveyState } from 'store/type'
import type { EnabledDrugTypesScreenPropsModel } from './EnabledDrugTypesScreen.model'

/**
 * A hidden screen used to set the drug types which are enabled for a specific survey
 * performed by a user in Redux, based on the drug types which are enabled for the current
 * project. Setting the enabled drug types per survey allows for the enabled drug types to
 * be taken into account for the conditional logic setups that the survey provides.
 *
 * @param props - The props this component takes.
 *
 * @returns A React component representing a hidden screen in the survey that sets the
 * drug types which are enabled for this survey instance.
 */
const EnabledDrugTypesScreenComponent: React.FC<
  EnabledDrugTypesScreenPropsModel
> = (props) => {
  const dispatch = useDispatch()

  const { name, enabledDrugTypes, footerProps } = props

  /**
   * Obtain the current enabled drug types from the project this survey is part of, save
   * this to the current survey's Redux state, and return the value of the enabled drug
   * types.
   *
   * @returns The enabled drug types for this survey (sourced from the project this survey
   * is being conducted as a part of).
   */
  const obtainAndSaveFieldValue = (): readonly UsingTypes[] => {
    // Save the enabled drug types to Redux for the current survey
    dispatch(
      updateSurveyAnswer({
        [name]: enabledDrugTypes
      })
    )

    // Return the enabled drug types too
    return enabledDrugTypes
  }

  useHiddenScreenLogic({
    logicAction: obtainAndSaveFieldValue,
    upcomingSurveyData: footerProps
  })

  return <></>
}

const mapStateToProps = (
  state: SurveyState
): DeepReadonly<{ enabledDrugTypes: UsingTypes[] }> => {
  const selectedProject = state.projects?.find(
    (project) => project.id === state.selectedProjectSite?.projectId
  )

  return {
    enabledDrugTypes:
      selectedProject?.enabledDrugTypes ?? Object.values(UsingTypes)
  }
}

/**
 * A hidden screen used to set the drug types which are enabled for a specific survey
 * performed by a user in Redux, based on the drug types which are enabled for the current
 * project. Setting the enabled drug types per survey allows for the enabled drug types to
 * be taken into account for the conditional logic setups that the survey provides.
 *
 * @returns A React component representing a hidden screen in the survey that sets the
 * drug types which are enabled for this survey instance.
 */
export const EnabledDrugTypesScreen = connect(mapStateToProps)(
  EnabledDrugTypesScreenComponent
)
