import MuiAutocomplete from '@mui/material/Autocomplete'
import Box from '@mui/material/Box'
import React, { useState, useMemo } from 'react'
import type { AutoCompleteLocationPropsModel } from './AutoCompleteLocation.model'
import styles from './AutoCompleteLocation.module.scss'
import { Text } from 'components/Text/Text.component'
import { Select } from 'components/Select/Select.component'
import { generateOptions } from 'shared/utils/generateOptions/generateOptions'
import Grid from '@mui/material/Grid'
import { colors } from 'shared/theme/theme'
import type { GeoLocation } from 'api/client.model'

const sortGeoLocations = (data: GeoLocation[]) => {
  return [...data].sort((a, b) => {
    if (a.locality < b.locality) {
      return -1
    }
    if (a.locality > b.locality) {
      return 1
    }
    return 0
  })
}

export const AutoCompleteLocation: React.FC<AutoCompleteLocationPropsModel> = (
  props
) => {
  const { dataList, value, setValue } = props
  const { generateStateOptions } = generateOptions()

  const [state, setState] = useState<string | undefined>(undefined)
  const [hightlight, setHighlight] = useState<GeoLocation | null>(null)

  const stateOptions = useMemo(() => {
    return generateStateOptions(dataList)
  }, [dataList, generateStateOptions])

  const filteredGeoLocations = useMemo(() => {
    if (!state) return []
    return sortGeoLocations(dataList.filter((item) => item.state === state))
  }, [dataList, state])

  const handleSelection = (selectionValue: string) => {
    setState(selectionValue)
    setValue(null)
    setHighlight(null)
  }

  return (
    <Grid
      className={styles.autocompletelocation}
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
    >
      <Grid item xs={4}>
        <Select
          fullWidth
          options={stateOptions}
          placeholder="Select State"
          setValue={handleSelection}
          selectProps={{
            inputProps: {
              'aria-label': 'State'
            }
          }}
          style={{
            marginRight: 20,
            backgroundColor: colors.white
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <MuiAutocomplete
          className={styles['autocompletelocation-input']}
          value={value}
          fullWidth
          clearOnBlur
          disablePortal
          disableClearable={!!value}
          options={filteredGeoLocations}
          autoHighlight
          autoComplete
          autoSelect
          forcePopupIcon={false}
          getOptionLabel={(option) =>
            `${option.locality}, ${option.state} - ${option.postCode}`
          }
          disabled={!state}
          renderOption={(optionProps, option, { inputValue }) => {
            const match =
              `${option.locality}, ${option.state} - ${option.postCode}` ===
                inputValue || option === hightlight
            return (
              <Box
                {...optionProps}
                className={styles['autocompletelocation-option']}
                component="li"
                key={`location-${option.locality}-${option.postCode}`}
                style={{
                  backgroundColor: match
                    ? 'rgba(25, 118, 210, 0.08)'
                    : undefined
                }}
              >
                {option.locality}, {option.state} - {option.postCode}
              </Box>
            )
          }}
          renderInput={(params) => (
            <Text
              textFieldProps={{
                ...params,
                placeholder: 'Enter Community Location',
                inputProps: {
                  ...params.inputProps,
                  'aria-label': 'Community Location'
                }
              }}
              style={{ backgroundColor: colors.white }}
            />
          )}
          onChange={(_event, selectedValue) => {
            setValue(selectedValue)
          }}
          onHighlightChange={(_event, option) => {
            setHighlight(option || null)
          }}
        />
      </Grid>
    </Grid>
  )
}
