import React, { useState } from 'react'
import { SideMenuPropsModel } from './SideMenu.model'
import styles from './SideMenu.module.scss'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { Icon } from 'components/Icons/Icon.component'
import { colors } from 'shared/theme/theme'
import classnames from 'classnames'
import { Modal } from 'components/Modals/Modal/Modal.component'
import { Button } from 'components/Button/Button.component'
import { Dispatch } from 'redux'
import { useDispatch } from 'react-redux'
import { logout } from 'store/reducer'
import { useNavigate } from 'react-router-dom'

export const SideMenu: React.FC<SideMenuPropsModel> = (props) => {
  const dispatch: Dispatch<any> = useDispatch()
  const navigate = useNavigate()

  const { options, value, setValue } = props

  const [activeValue, setActiveValue] = useState<string>(value)
  const [logoutModal, setLogoutModal] = useState<boolean>(false)

  const handleMenuClick = (menu: string) => {
    setActiveValue(menu)
    setValue(menu)
  }

  const handleLogout = () => {
    dispatch(logout())
    navigate('/ra-dashboard')
  }

  return (
    <nav className={styles.sidemenu}>
      <Drawer
        className={styles['sidemenu-drawer']}
        sx={{
          flexShrink: 0
        }}
        variant="permanent"
        anchor="left"
      >
        <List className={styles['sidemenu-list']}>
          {options.map((option, index) => (
            <ListItem
              key={`side-menu-list-item-${index}`}
              disablePadding
              className={classnames({
                [styles.activeItem]: activeValue === option.value
              })}
              onClick={() => {
                option.value === 'logout'
                  ? setLogoutModal(true)
                  : handleMenuClick(option.value)
              }}
            >
              <ListItemButton className={styles['sidemenu-listItemButton']}>
                <ListItemIcon>
                  <Icon
                    icon={option.icon}
                    size={35}
                    fill={colors.white}
                    className={styles['sidemenu-listItemButton-icon']}
                  />
                </ListItemIcon>
                <ListItemText
                  className={styles['sidemenu-listItemButton-text']}
                  primary={option.label}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </Drawer>
      <Modal
        className={styles['sidemenu-modal']}
        open={logoutModal}
        buttons={
          <div className={styles['sidemenu-modal-buttons']}>
            <Button variation="primary" width="l" onClick={handleLogout}>
              Yes
            </Button>
            <Button
              variation="secondary"
              width="l"
              onClick={() => setLogoutModal(false)}
            >
              Cancel
            </Button>
          </div>
        }
        style={{ backgroundColor: colors.white }}
      >
        <h2>Are you sure you want to log out?</h2>
      </Modal>
    </nav>
  )
}
