import React from 'react'
import { connect, useDispatch } from 'react-redux'
import type { Dispatch } from 'redux'
import { matchCondition } from 'shared/utils/matchCondition/matchCondition'
import { updateSurveyAnswer } from 'store/reducer'
import type { SurveyState } from 'store/type'
import type { UsingStatusScreenPropsModel } from './UsingStatusScreen.model'
import { useHiddenScreenLogic } from 'shared/utils/hiddenScreenLogic/hiddenScreenLogic'

export const UsingStatusScreenComponent: React.FC<
  UsingStatusScreenPropsModel
> = (props) => {
  const dispatch: Dispatch<any> = useDispatch()

  const { isMatchCondition } = matchCondition()

  const {
    name,
    screenData: { options },
    footerProps,
    states
  } = props

  /**
   * Obtain the values of the using status of the user for this screen, save this to the
   * Redux state of the application, and return the same value back to the user.
   *
   * @returns A using status for a drug type (based on some conditions).
   */
  const obtainAndSaveFieldValue = (): string[] => {
    const value: string[] = []
    if (states) {
      options.forEach((option) => {
        if (option.conditions && option.conditions.length > 0) {
          const result = option.conditions.some((condition) =>
            isMatchCondition(condition, states[condition.dependingScreen])
          )
          if (result) {
            value.push(option.value || option.label)
          }
        }
      })
    }

    dispatch(
      updateSurveyAnswer({
        [name]: value.length == 0 ? null : value
      })
    )

    return value
  }

  useHiddenScreenLogic({
    logicAction: obtainAndSaveFieldValue,
    upcomingSurveyData: footerProps
  })

  return <></>
}

const mapStateToProps = (state: SurveyState) => ({
  states: state.surveyData
})

export const UsingStatusScreen = connect(mapStateToProps)(
  UsingStatusScreenComponent
)
