import { useContext, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { AudioContext } from 'context/AudioContext'
import { updateUserJourney } from 'store/reducer'
import type { HiddenScreenLogicHookType } from './hiddenScreenLogic.model'
import { matchCondition } from '../matchCondition/matchCondition'

/**
 * A custom hook used to encapsulate the main flow of logic used on hidden screens, that
 * is, to:
 *
 * - remove this hidden screen from the user journey of the user.
 * - perform some sort of logic action that the hidden screen should execute.
 * - get the ID of the next screen to navigate to (taking into account any conditional
 *   flow, as required).
 * - then, at the end, navigate to the next screen, and play any audio for the next
 *   screen, as required.
 *
 * @template TActionReturnType - the return type of the custom action to execute in this
 * hook. The value of the action is used in part to assist in determining the next screen
 * ID.
 * @param props - The props which dictate the logical action to execute on this hidden
 * screen, as well as the data about the next upcoming screen in the survey.
 */
export const useHiddenScreenLogic = <TActionReturnType>(
  props: HiddenScreenLogicHookType<TActionReturnType>
) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { getNextScreenId } = matchCondition()

  const { logicAction, upcomingSurveyData } = props
  const { playAudio } = useContext(AudioContext)

  useEffect(() => {
    dispatch(updateUserJourney(-1))

    // Perform the logic that is desired
    const logicValue = logicAction()

    const screenId = getNextScreenId(
      logicValue,
      upcomingSurveyData.conditions,
      upcomingSurveyData.nextScreenId
    )

    const nextScreenId = screenId || upcomingSurveyData.nextScreenId
    navigate(`/survey/${nextScreenId}`, { replace: true })
    // Update screen path
    dispatch(updateUserJourney(Number(nextScreenId)))

    playAudio?.(nextScreenId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
}
