import React, { useContext, useEffect, useRef, useState } from 'react'
import classnames from 'classnames'
import { v4 as uuidv4 } from 'uuid'
import {
  MultipleChoiceModel,
  MultipleChoicePropsModel
} from './MultipleChoice.model'
import styles from './MultipleChoice.module.scss'
import Paper from '@mui/material/Paper'
import Box from '@mui/material/Box'
import { Icon } from 'components/Icons/Icon.component'
import { IconType } from 'components/Icons/Icon.model'
import { colors } from 'shared/theme/theme'
import { Carousel } from 'components/Carousel/Carousel.component'
import { sliceIntoChunks } from 'shared/utils/sliceIntoChunks/sliceIntoChunks'
import { OptionalButtons } from 'components/OptionalButtons/OptionalButtons.component'
import { BorderType } from 'components/Border/Border.model'
import { Border } from 'components/Border/Border.component'
import store from 'store/store'
import { AudioContext } from 'context/AudioContext'
import { isString } from 'shared/types/guards'
import { useColourPicker } from 'context/ColourContext'
import { surveyResponseValueToArray } from 'shared/utils/surveyDataConverter/surveyDataConverter'

const CONST_OPTION_STYLES = {
  withIllustration: {
    'display': 'flex',
    'flexDirection': 'column',
    'gridColumn': '1 / 3',
    'justifyContent': 'center',
    'gridTemplateColumns': `repeat(8, 1fr)`,
    'height': 'calc(100vh - 355px)'
  },
  withIllustrationAndOptional: {
    'display': 'flex',
    'flexDirection': 'column',
    'gridColumn': '1 / 3',
    'justifyContent': 'center',
    'gridTemplateColumns': `repeat(8, 1fr)`,
    'height': 'calc(100vh - 430px)'
  }
} as const

export const MultipleChoice = <TOptional = string,>(
  props: MultipleChoicePropsModel<TOptional>
): JSX.Element => {
  const {
    className,
    title,
    subtitle,
    options,
    isMultiple: multiple,
    columnNum = 4,
    defaultValue,
    setValue,
    isCarousel,
    optionalButtons,
    onOptionalButtonSelect,
    vertical,
    withTick = true,
    image,
    altText,
    sideLayout,
    defaultImage,
    borderType,
    leftAligned,
    optionsDisabled,
    dependingScreen,
    ...componentProps
  } = props
  const { secondaryColour } = useColourPicker()
  const { playAudio } = useContext(AudioContext)
  const [validOptions, setValidOptions] = useState<MultipleChoiceModel[]>([])
  const [dataLength, setDataLength] = useState<number>(0)
  const [imageUrl, setImageUrl] = useState<string>(defaultImage)
  const [selected, setSelected] = useState<string>('')
  const [resetOptionalButtons, setResetOptionalButtons] =
    useState<boolean>(false)

  const idRef = useRef<string>('')

  const getId = (): string => {
    if (!idRef.current) {
      idRef.current = uuidv4().slice(0, 8)
    }
    return idRef.current
  }

  const calcGridColumnEnd = (index: number) => {
    let cNum = columnNum
    if (isCarousel && dataLength > columnNum) {
      cNum = dataLength
    }
    if (!leftAligned && validOptions.length % cNum !== 0) {
      const ophanIndex = validOptions.length % cNum
      for (let i = 0; i < ophanIndex; i++) {
        if (validOptions.length - i === index + 1) {
          return -(cNum * 2 - (cNum - ophanIndex + 1) - (index % cNum) * 2)
        }
      }
    }
    return 'auto'
  }

  const renderLabel = (label: string) => {
    if (label.includes('(')) {
      const indexOfBracket = label.indexOf('(')
      const mainStr = label.slice(0, indexOfBracket)
      const subStr = label.slice(indexOfBracket)
      return (
        <>
          {mainStr}
          <span className={styles['multiplechoice-option__text--small']}>
            {subStr}
          </span>
        </>
      )
    }
    return label
  }

  const handleOptionalButtonClick = (value: TOptional) => {
    if (onOptionalButtonSelect) {
      onOptionalButtonSelect(value)
    } else {
      const valueToSet = isString(value) ? value : String(value)
      setValue([valueToSet])
    }

    const otherOptions = [...validOptions]
    otherOptions.forEach((option) => (option.selected = false))
  }

  const onItemClick = (index: number, exclusive?: boolean) => {
    if (optionsDisabled) {
      return false
    }
    setResetOptionalButtons(!resetOptionalButtons)
    const updateOptions = [...validOptions]
    updateOptions[index].selected = multiple
      ? !updateOptions[index].selected
      : true

    if (!multiple || exclusive) {
      updateOptions.forEach((option, optionIndex) => {
        if (optionIndex !== index) {
          option.selected = false
        }
        return updateOptions
      })
    }

    if (multiple && !exclusive) {
      updateOptions.forEach((option) => {
        if (option.isExclusive === 1) {
          option.selected = false
        }
        return updateOptions
      })
    }

    // Play option audio
    if (
      updateOptions[index]?.voices &&
      updateOptions[index]?.id &&
      // Do not play if is multiple and the currently selected option is clicked
      !(multiple && !validOptions[index]?.selected)
    ) {
      playAudio?.(`${updateOptions[index]?.id}-option`)
    }

    setValue(
      updateOptions
        .filter((option) => option.selected)
        .map((validItem) => validItem.value)
    )
    setValidOptions(updateOptions)
  }

  useEffect(() => {
    const selectedOption: MultipleChoiceModel[] = validOptions.filter(
      (option) => option.selected === true
    )
    let dynamicImageUrl
    if (selectedOption[0]) {
      setSelected(selectedOption[0].label)
      dynamicImageUrl = selectedOption[0].image
        ? selectedOption[0].image
        : defaultImage
    } else {
      dynamicImageUrl = defaultImage
    }
    setImageUrl(dynamicImageUrl)
  }, [defaultImage, validOptions])

  useEffect(() => {
    setDataLength(validOptions.length)
    if (defaultValue) {
      const defaultOptions = [...validOptions]
      defaultOptions.forEach((option) => {
        if (defaultValue.includes(option.value)) {
          option.selected = true
        }
        return option
      })
    }
  }, [defaultValue, validOptions])

  useEffect(() => {
    if (options) {
      let optionsModified = options
        .filter((item) => item.isActive !== 0)
        .map((item) =>
          item.selected === undefined ? { ...item, selected: false } : item
        )

      const reduxValues = store.getState().surveyData
      if (dependingScreen && dependingScreen.length > 0 && reduxValues) {
        let dependingValues: string[] = []

        dependingScreen.forEach((screen) => {
          if (reduxValues[screen]) {
            const arr = surveyResponseValueToArray(reduxValues[screen])
            dependingValues = dependingValues.concat(arr)
          }
        })
        optionsModified = optionsModified.filter((item) =>
          dependingValues.includes(item.value)
        )
      }

      setValidOptions(optionsModified)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options])

  const optionsStyles = {
    'gridTemplateColumns': `repeat(${columnNum * 2}, 1fr)`
  }

  const optionsWithCarouselStyles = {
    'gridTemplateColumns': `repeat(${
      dataLength > columnNum ? dataLength * 2 : columnNum * 2
    }, 1fr)`
  }

  /**  Sets grid col based on columnNum prop if withCarousel is false,
    otherwise to no. of options x 2 */
  const optionStyleCondition = () => {
    if (vertical) {
      if (optionalButtons) {
        return CONST_OPTION_STYLES.withIllustrationAndOptional
      }
      return CONST_OPTION_STYLES.withIllustration
    }
    if (isCarousel) {
      return optionsWithCarouselStyles
    }
    return optionsStyles
  }

  const backgroundColorCondition = (option: MultipleChoiceModel) => {
    if (option.selected) {
      return secondaryColour
    }
  }

  const markupStyle = (index: number) => {
    if (!isCarousel || validOptions.length < 7)
      return { gridColumnEnd: calcGridColumnEnd(index) }
  }

  const generateQuestionsMarkup = () => {
    const rowNum =
      isCarousel &&
      validOptions.length > 6 &&
      validOptions.filter((o) => o.image).length < 1
        ? 2
        : 1

    const hasImages = !!validOptions.find((item) => item.image)
    const chunkValidOptions: MultipleChoiceModel[][] = sliceIntoChunks(
      [...validOptions],
      rowNum
    )

    return chunkValidOptions.map((chunk, chunkIndex) => (
      <div
        key={`multiple-choice-${chunkIndex}-chunk`}
        style={markupStyle(chunkIndex)}
      >
        {chunk.map((option, index) => {
          const imageHeight =
            hasImages || option.image ? 'calc(100% - 10px)' : '100%'
          const height = rowNum > 1 ? '100px' : imageHeight
          return (
            <div
              key={`multiple-choice-${index}-option`}
              className={option.image && styles['multiplechoice__option--tall']}
              style={{
                position: 'relative',
                height,
                zIndex: 0,
                marginTop: withTick ? 24 : 0
              }}
            >
              <Border
                type={borderType || BorderType.Option1}
                fill={backgroundColorCondition(option) || colors.white}
                dropShadow
                transform
              />
              <Paper
                className={classnames(
                  styles['multiplechoice-option'],
                  'u-border-style-sketch',
                  {
                    [styles['multiplechoice-option--selected']]:
                      !!option.selected,
                    [styles['multiplechoice-option--withImage']]: option.image,
                    [styles['multiplechoice-option--vertical']]: vertical,
                    [styles['multiplechoice-option--vertical-withImage']]:
                      vertical && option.optionImage,
                    [styles['multiplechoice-option--disabled']]: optionsDisabled
                  }
                )}
                name={`options-${
                  multiple ? `${chunkIndex}-${index}` : ''
                }-${getId()}`}
                component={'button'}
                type="button"
                role={multiple ? 'checkbox' : 'radio'}
                aria-checked={option.selected ? 'true' : 'false'}
                aria-label={option.label}
                sx={{
                  height: rowNum > 1 ? '100% !important' : undefined,
                  padding: vertical || option.image ? '10px' : '25px 15px'
                }}
                onClick={() =>
                  onItemClick(
                    index + chunkIndex * rowNum,
                    option.isExclusive === 1
                  )
                }
              >
                {!vertical && option?.image && (
                  <div className={styles['multiplechoice-option--image']}>
                    <img src={option.image} alt={'Image of ' + option.label} />
                  </div>
                )}
                {vertical && option?.optionImage && (
                  <div
                    className={styles['multiplechoice-option-vertical--image']}
                  >
                    <img
                      src={option.optionImage}
                      alt={'Option image of ' + option.label}
                    />
                  </div>
                )}
                {option.selected && withTick && (
                  <Icon
                    className={styles['multiplechoice-option--icon']}
                    icon={IconType.Tick}
                    size={32}
                    stroke={colors.black}
                    fill={colors.white}
                    border
                  />
                )}
                {renderLabel(option.label)}
              </Paper>
            </div>
          )
        })}
      </div>
    ))
  }

  // Not rendering if no options available
  if (validOptions.length === 0) {
    return <></>
  }

  return (
    <div
      {...componentProps}
      className={classnames(styles.multiplechoice, className, {
        [styles['multiplechoice--side']]: sideLayout
      })}
    >
      <div className={styles['multiplechoice-body']}>
        {!vertical && title && (
          <h1
            className={classnames(
              'screen-titles--title',
              styles['multiplechoice-title']
            )}
            dangerouslySetInnerHTML={{ __html: title }}
          />
        )}
        {subtitle && (
          <p
            className={classnames(
              'screen-titles--subtitle',
              styles['multiplechoice-subtitle']
            )}
            dangerouslySetInnerHTML={{ __html: subtitle }}
          />
        )}
        <div
          className={classnames(
            styles['multiplechoice-wrapper'],
            'wrapper-style',
            {
              'no-padding': isCarousel && validOptions.length > 6,
              [styles['multiplechoice-wrapper--topSpace']]:
                title &&
                validOptions.length > columnNum &&
                (title.length > 100 || (title.length > 100 && subtitle))
            }
          )}
          style={{ marginTop: subtitle ? '-50px' : undefined }}
        >
          <Box
            className={classnames(
              styles['multiplechoice-container'],
              'box-style'
            )}
            sx={{
              display: 'grid',
              gridTemplateColumns: `repeat(${
                isCarousel ? 5 : columnNum * 2
              }, 1fr)`
            }}
          >
            {validOptions.length > 6 && isCarousel ? (
              <div className={styles['multiplechoice__slider-container']}>
                <Carousel slidesToShow={columnNum}>
                  {generateQuestionsMarkup()}
                </Carousel>
              </div>
            ) : (
              <>
                <div
                  className={classnames(
                    styles['multiplechoice-options'],
                    'option-style'
                  )}
                  style={optionStyleCondition()}
                >
                  {generateQuestionsMarkup()}
                </div>
                {vertical && (
                  <div
                    className={
                      optionalButtons
                        ? styles['multiplechoice__illustration-optional']
                        : styles['multiplechoice__illustration']
                    }
                  >
                    <img
                      src={imageUrl}
                      alt={`Image for ${selected || 'Default Image'}`}
                    />
                  </div>
                )}
              </>
            )}
          </Box>
          {optionalButtons && (
            <OptionalButtons
              optionalButtons={optionalButtons}
              color={secondaryColour}
              reset={resetOptionalButtons}
              onButtonSelect={handleOptionalButtonClick}
              style={vertical ? { marginTop: '24px' } : undefined}
            />
          )}
        </div>
      </div>
      {!!image && !isCarousel && sideLayout && !vertical && (
        <div className={styles['multiplechoice-image']}>
          <img src={image} alt={altText || `Image for ${title}`} />
        </div>
      )}
    </div>
  )
}
