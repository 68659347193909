import { TextScreen } from './TextScreen/TextScreen'
import { MultipleChoiceScreen } from './MultipleChoiceScreen/MultipleChoiceScreen'
import { MultipleChoiceImageScreen } from './MultipleChoiceImageScreen/MultipleChoiceImageScreen'
import { SliderScreen } from './SliderScreen/SliderScreen'
import { MultipleChoice } from 'components/MultipleChoice/MultipleChoice.component'
import { PostCodeScreen } from './PostCodeScreen/PostCodeScreen'
import { Slider } from 'components/Slider/Slider.component'
import { CalendarDateScreen } from './CalendarDateScreen/CalendarDateScreen'
import { DynamicSliderScreen } from './DynamicSliderScreen/DynamicSliderScreen'
import { GrogDiaryScreen } from './GrogDiaryScreen/GrogDiaryScreen'
import { CommunityLocationsScreen } from './CommunityLocationsScreen/CommunityLocationsScreen'
import { AudioSelectScreen } from './AudioSelectScreen/AudioSelectScreen'
import { SectionProgressScreen } from './SectionProgressScreen/SectionProgressScreen'
import { InputScreen } from './InputScreen/InputScreen'
import { FeedbackScreen } from './FeedbackScreen/FeedbackScreen'
import { SymptomsCircleScreen } from 'screens/outro/SymptomsCircleScreen/SymptomsCircleScreen'
import { EffectsPieChartScreen } from 'screens/outro/EffectsPieChartScreen/EffectsPieChartScreen'
import { UsingAmountScreen } from 'screens/outro/UsingAmountScreen/UsingAmountScreen'
import { EffectsCarouselScreen } from 'screens/outro/EffectsCarouselScreen/EffectsCarouselScreen'
import { TipsScreen } from 'screens/outro/TipsScreen/TipsScreen'
import { AlcoholRiskScreen } from 'screens/outro/AlcoholRiskScreen/AlcoholRiskScreen'
import { ContactsScreen } from 'screens/outro/ContactsScreen/ContactsScreen'
import { TextBoxScreen } from 'screens/outro/TextBoxScreen/TextBoxScreen'
import { UsingStatusScreen } from './UsingStatusScreen/UsingStatusScreen'
import { StandardDrinksScreen } from 'screens/outro/StandardDrinksScreen/StandardDrinksScreen'
import { EnabledDrugTypesScreen } from './EnabledDrugTypesScreen/EnabledDrugTypesScreen'

export enum ScreenType {
  audioSelect = 'audioSelect',
  calendarDate = 'calendarDate',
  communityLocations = 'communityLocations',
  dynamicSlider = 'dynamicSlider',
  feedbackScreen = 'feedbackScreen',
  'grogDiary' = 'grogDiary',
  input = 'input',
  multipleChoice = 'multipleChoice',
  multipleChoiceImage = 'multipleChoiceImage',
  postCode = 'postCode',
  slider = 'slider',
  text = 'text',
  usingStatus = 'usingStatus',

  // Outro
  outroAlcoholRiskTriangle = 'outroAlcoholRiskTriangle',
  outroHelpContacts = 'outroHelpContacts',
  outroEffectsCarousel = 'outroEffectsCarousel',
  effectsPieChart = 'effectsPieChart',
  symptomsCircle = 'symptomsCircle',
  outroStandardDrinks = 'outroStandardDrinks',
  outroTextBox = 'outroTextBox',
  outroTips = 'outroTips',
  outroUsingAmount = 'outroUsingAmount'
}

export const ScreenComponent = {
  'audioSelect': AudioSelectScreen,
  'calendarDate': CalendarDateScreen,
  'communityLocations': CommunityLocationsScreen,
  'dynamicSlider': DynamicSliderScreen,
  'feedbackScreen': FeedbackScreen,
  'grogDiary': GrogDiaryScreen,
  'input': InputScreen,
  'multipleChoice': MultipleChoiceScreen,
  'multipleChoiceImage': MultipleChoiceImageScreen,
  'postCode': PostCodeScreen,
  'sectionProgress': SectionProgressScreen,
  'slider': SliderScreen,
  'text': TextScreen,
  'usingStatus': UsingStatusScreen,
  'enabledDrugTypes': EnabledDrugTypesScreen,

  // Outro
  outroAlcoholRiskTriangle: AlcoholRiskScreen,
  outroHelpContacts: ContactsScreen,
  outroEffectsCarousel: EffectsCarouselScreen,
  effectsPieChart: EffectsPieChartScreen,
  symptomsCircle: SymptomsCircleScreen,
  outroStandardDrinks: StandardDrinksScreen,
  outroTextBox: TextBoxScreen,
  outroTips: TipsScreen,
  outroUsingAmount: UsingAmountScreen
}

export interface genericObject {
  [key: string]: string
}
