import './wdyr'

import React from 'react'
import ReactDOM from 'react-dom/client'
import { App } from 'components/App/App.component'
import * as serviceWorkerRegistration from './shared/utils/serviceWorker/registration'
import reportWebVitals from './reportWebVitals'
import { theme } from 'shared/theme/theme.provider'
import { ThemeProvider } from '@mui/material/styles'
import { getSharedBrowserHistory } from 'shared/utils/browserHistory/browserHistory'
import { BrowserRouter as Router, RouterProps } from 'react-router-dom'
import { History } from 'history'
import { Provider } from 'react-redux'
import store from 'store/store'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
const browserHistory = getSharedBrowserHistory()

const CustomRouter = ({
  basename,
  children,
  history
}: Partial<RouterProps> & { history: History }) => {
  const [state, setState] = React.useState({
    action: history.action,
    location: history.location
  })

  React.useLayoutEffect(() => history.listen(setState), [history])

  return (
    <Router {...state} basename={basename}>
      {children}
    </Router>
  )
}

const persistor = persistStore(store)

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <ThemeProvider theme={theme}>
        <CustomRouter history={browserHistory}>
          <App />
        </CustomRouter>
      </ThemeProvider>
    </PersistGate>
  </Provider>
)

window.onbeforeunload = async function () {
  console.log('Persist to local storage before exit the app')
  persistor.persist()
  await persistor.flush()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
