import React, { createRef, useEffect, useRef, useState } from 'react'
import TextField from '@mui/material/TextField'
import { PinPropsModel } from './Pin.model'
import styles from './Pin.module.scss'
import { Border } from 'components/Border/Border.component'
import { BorderType } from 'components/Border/Border.model'
import { colors } from 'shared/theme/theme'
import classnames from 'classnames'

export const Pin: React.FC<PinPropsModel> = (props) => {
  const {
    title,
    pinValue,
    setValue,
    setValueArray,
    readonly,
    isPassword = false
  } = props

  const [pin, setPin] = useState<string[]>(['', '', '', ''])
  const pinDigits = Array.from({ length: 4 }, (_, i) => i)
  const elementsRef = useRef(pinDigits.map(() => createRef<HTMLDivElement>()))

  const onNumChange = (value: string, index: number) => {
    const resetPin = [...pin]
    resetPin[index] = value.replace(/\D/g, '')
    setPin(resetPin)

    if (setValue) {
      setValue(resetPin.join().replaceAll(',', ''))
    }

    if (setValueArray) {
      setValueArray(resetPin)
    }

    const nextPinRef = elementsRef.current[index + 1]
    const prevPinRef = elementsRef.current[index - 1]

    if (
      // on finishing typing digit, focus on next
      resetPin[index] &&
      resetPin[index] !== '' &&
      nextPinRef &&
      nextPinRef.current
    ) {
      nextPinRef.current.focus()
    } else if (
      // on deleting digit, focus on previous
      (!resetPin[index] || resetPin[index] === '') &&
      prevPinRef &&
      prevPinRef.current
    ) {
      prevPinRef.current.focus()
    }
  }

  useEffect(() => {
    if (pinValue) {
      setPin(pinValue)
    }
  }, [pinValue])

  return (
    <fieldset className={styles.pin}>
      {title && <legend className={styles['pin-title']}>{title}</legend>}
      <div className={styles['pin-wrapper']}>
        {pinDigits.map((index) => (
          <div
            key={`pin-input-${index}`}
            style={{ position: 'relative', zIndex: 0 }}
          >
            <Border type={BorderType.Option2} fill={colors.white} dropShadow />
            <TextField
              className={classnames(
                styles['pin-digit'],
                'u-border-style-sketch'
              )}
              autoFocus={index === 0 && readonly ? true : false}
              id={`pin-${index + 1}`}
              inputProps={{
                disabled: readonly,
                ref: elementsRef.current[index],
                inputMode: 'numeric',
                required: true,
                pattern: /\D/g,
                minLength: 1,
                maxLength: 1,
                'aria-label': `Pin digit ${index + 1}`,
                onChange: (e) => onNumChange(e.currentTarget.value, index),
                value: isPassword ? (pin[index] ? '*' : '') : pin[index]
              }}
            />
          </div>
        ))}
      </div>
    </fieldset>
  )
}
